@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

body {
    margin: 0;
    font-family: 'Architects Daughter', cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app {
    min-height: 100vh;
    min-width: 100vw;
}

.header {
    text-align: center;
}

.form {
    margin-top: 80px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.result {
    color: white;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

input {
    color: black;
}

.heart {
    display: block;
    width: 70px;
    height: 70px;
}
